import { h, Component } from 'preact';
import * as React from 'preact';
import * as ReactDOM from 'preact';
import "./picker-menu.scss"

export default class PickerMenu extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      open: false
    }
  }

  getOptions = () => {
    return this.props.options.map(value => {
      return <div className="item" onClick={()=>{
          this.setState({open: false}, ()=>{
            this.props.onChange(value)
          })
        }}>
        {value}
        </div>
    })
  }

//  style={{display: this.state.open ? 'block' : 'none'}}
  render(){
    return(
      <div className="picker-menu">
        <div className="picker-select-box" onClick={()=>{
          this.setState({open: !this.state.open})
        }}>
        { this.props.value }
        <div className={`arrow ${this.state.open?'up':'down'}`}/>
        </div>
        <div className="picker-options-container" style={{maxHeight: this.state.open ? '200px' : '0'}}>
        { this.getOptions() }
        </div>
      </div>      
    )
  }

}
