import React from 'react'
import {render} from 'react-dom'
import styled from 'styled-components'

import Microsite from './routes/microsite'
import Router from 'preact-router';

render((
<Router>
  <Microsite path="/:videoid/:versionid"/>
  <Microsite path="/preview/:videoid" bootstrap/>
 </Router>
), document.getElementById('app'))