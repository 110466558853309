import { h, Component } from 'preact';
import * as React from 'preact';
import * as ReactDOM from 'preact';

import { whitefreckle, bluefreckle } from './button-vectors'

export default class Freckle extends React.Component {
	constructor(props, context) {
		super(props, context);
	}

	selected = (freckleId) => {
		this.props.selected(freckleId, this.props.trackRef)
	}

	hoverStart = () => {
		this.props.selected(this.props.freckleId, this.props.trackRef)
	}

	render(){
		let freckleVizClass = "freckleViz"
		const freckleItem = whitefreckle
		return(
			<div ref={this.props.trackRef} class="freckleItem"  onClick={()=>{this.selected(this.props.freckleId)}}
			style={{"animationName": "anim-" + this.props.freckleId + "-freckle", 
					"animationDuration": this.props.durationSeconds + "s"}}>
					<div class="freckleInnerItem" style={{
						"animationPlayState": this.props.isSelected ? "paused" : "running"
					}}>
					<img style={{"height":"20px", "width":"20px", "opacity": this.props.isSelected ? "1":"1"}} src={`data:image/svg+xml;base64,${btoa(freckleItem)}`}/>
					</div>
			</div>
		)
	}
}