import { uid } from 'uid';
import 'regenerator-runtime/runtime'
const axios = require('axios')

class Analytics {
	constructor(){
		if(! Analytics.instance){
			this.events = [];
			this.sessionId = uid(32)
			this.eventsCalledSet = new Set()
			document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this));
			Analytics.instance = this;
		}

		return Analytics.instance;
	}

	handleVisibilityChange = async (event) => {
		return
		if(this.events.length > 0 && (document.visibilityState === 'unloaded' || document.visibilityState === 'hidden')) {
			await this.sendEvents()
			this.events.length = 0
		}
	}

	logEvent = (eventName, productId, videoId, videoTime) => {
		return
		const payload = {
			eventname: eventName,
			sessionid: this.sessionId,
			firstevent: !this.eventsCalledSet.has(eventName),
			timestamp: (new Date()).toISOString(),
			productid: productId,
			videoid: videoId,
			videotime: new Date(videoTime * 1000).toISOString().substr(11, 12)
		}
		this.events.push(payload);
		this.eventsCalledSet.add(eventName)
	}

	sendEvents = async () => {
		return
		try {
			const payload = {
				events: this.events
			}
			await axios.post('https://dev-t.freckle.shop/event', payload)
			return
		} catch(e) {
			console.log('failed to send events: ' + e)
			return
		}
	}
}

const instance = new Analytics();
Object.freeze(instance);

export default instance;