import { h, Component, createRef } from 'preact'
import * as React from 'preact';
import * as ReactDOM from 'preact';

export default class ScrubBar extends React.Component {

  constructor(props, context) {
      super(props, context);
      this.scrubBar = createRef();
      this.state = {
        scrubbing: false,
        scrubOffset: null
      }

      this.scrubRef = createRef();
  }

  componentDidMount() {
    requestAnimationFrame(this.updateTime.bind(this))
  }

  updateTime = (e) => {
    var perc = 0
    const player = this.props.player

    if(player.currentTime != null && player.duration != null) perc = player.currentTime / player.duration
    this.scrubRef.current.style.marginLeft = -((1-perc) * 100) + "%"

    requestAnimationFrame(this.updateTime.bind(this))
  }

  valueChanged = (offset) => {
    this.props.valueChanged(offset)
  }

  mouseDown(e) {
    var rect = e.target.getBoundingClientRect();
    var xOffset = e.clientX - rect.left;
    let width = rect.width
    let scrubOffset = (xOffset / width)

    this.valueChanged(scrubOffset)

    this.setState({scrubbing: true})
  }

  mouseMoved(e) {
    if(this.state.scrubbing){
      var rect = e.target.getBoundingClientRect();
      var xOffset = e.clientX - rect.left;
      let width = rect.width

      let scrubOffset = (xOffset / width)

      this.valueChanged(scrubOffset)
    }
  }

  mouseCancel(e) {
    this.setState({scrubbing: false, scrubOffset: null})
  }

  touchStart(e) {
    var rect = e.target.getBoundingClientRect();
    var xOffset = e.touches[0].clientX - rect.left;
    let width = rect.width

    let scrubOffset = (xOffset / width)

    this.valueChanged(scrubOffset)

    this.setState({scrubbing: true})
  }

  touchMove(e) {
    if(this.state.scrubbing){
      var rect = e.target.getBoundingClientRect();
      var xOffset = e.touches[0].clientX - rect.left;
      let width = rect.width

      let scrubOffset = (xOffset / width)

      this.valueChanged(scrubOffset)
    }
  }

  touchCancel(e) {
    this.setState({scrubbing: false})
  }

	render() {
		return (

      <div class="progressContainer"
        onMouseUp={this.mouseCancel.bind(this)} 
        onMouseLeave={this.mouseCancel.bind(this)} 
        onMouseDown={this.mouseDown.bind(this)} 
        onMouseMove={this.mouseMoved.bind(this)} 
        onTouchStart={this.touchStart.bind(this)}
        onTouchMove={this.touchMove.bind(this)}
        onTouchEnd={this.touchCancel.bind(this)}>
        <div class="progressTrack">
                <div ref={this.scrubRef} class="progressBar"></div>
        </div>
        {
          // <div class="timesContainer">
          // <div class="currentTimeLabel">2:00</div>
          // <div class="durationLabel">2:33</div>
          // </div>
        }
      </div>
		)
	}
}