import style from './style.scss';
import { h, Component, createRef } from 'preact'

import * as React from 'preact';
import * as ReactDOM from 'preact';

import axios from 'axios'
import memoize from "memoize-one"
import Freckle from './freckle'
import ScrubBar from './scrubbar'
import Product from '../product'

import register from 'preact-custom-element';

import ReactResizeDetector from 'react-resize-detector';

import Analytics from '../../helpers/analytics'

import { CSSTransition } from 'react-transition-group'

import { play, pause, volMuted, vol, playBlack, replayBlack } from './button-vectors'

import Hls from 'hls.js'

const LOADING = 0
const LOADED = 1
const LOAD_FAIL = 2

const mobile = require('is-mobile');

export default class Player extends React.Component {

  constructor(props, context) {
  	super(props, context);

  	this.state = {
      template: null,
      shopBase: null,
      playing: false,
      products: [],
      duration: null,
      selectedFreckleId: null,
      selectedFreckleRef: null,
      wasPlayingOnSelect: false,
      loading: LOADING,
      playbackId: null,
      aspectRatio: 0,
      freckleLayerHeight: 0,
      freckleLayerWidth: 0,
      showsControls: true,
      canPlay: false,
      waitingToPlay: (!eval(props.autoplay) || !eval(props.muted)),
      videoEnded: false, 
      videoHeight: 0,
      videoWidth: 0,
      videoId: this.props.videoid.split(":")[0]
    };

    this.controlsTimer = null

    this.videoPlayer = null
  	// this.videoPlayer = createRef();

    this.freckleRefs = {}
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }

  // antipattern but...eh
  componentWillReceiveProps(nextProps) {
    if(this.props.data != nextProps.data && nextProps.local) {
      this.handleData(JSON.parse(nextProps.data))
    }
  }

  componentDidMount() {
    if(!this.props.local){
      this.fetchData()
    } else if(this.props.data !== null) {
      this.handleData(JSON.parse(this.props.data))
    }

    this.controlsTimer = setTimeout(()=>{
      this.setState({showsControls: false})
      this.controlsTime = null
    }, 1500)

    this.loadHLSVideo()
  }

  fetchData = () => {
    if(this.props.videoid == null){
      this.setState({loading: LOAD_FAIL})
      return
    }

    const comps = this.props.videoid.split(":")
    const path = `https://stream.freckle.shop/${comps[0]}/manifests/${comps[1]}.json`
    
    axios.get(path).then((payload)=>{
      const data = payload.data
      this.handleData(data)
    }).catch((error) => {
      this.setState({loading: LOAD_FAIL})
    })
  }

  loadHLSVideo = () => {
    if(!!!this.videoPlayer) return
    const hlsUrl = `https://stream.freckle.shop/${this.props.videoid.split(":")[0]}/index.m3u8`
    if (Hls.isSupported()) {
      var video = this.videoPlayer
      var hls = new Hls();
        // bind them together
        hls.attachMedia(video);
        hls.on(Hls.Events.MEDIA_ATTACHED, () => {
          hls.loadSource(hlsUrl);
        });
      } else {
        console.log("videoPlayer: " + JSON.stringify(this.videoPlayer))
        const source = document.createElement("source")
        source.src = hlsUrl;
        this.videoPlayer.appendChild(source)
        this.videoPlayer.load()
      }
    }

  handleData = (data) => {
    if(data !== null) {
      this.setState({products: data.products, loading: LOADED, playbackId: data.playbackId, template: data.template}, ()=>{
        this.configureEventListeners()
        requestAnimationFrame(this.updateLocations.bind(this))
        this.loadHLSVideo()
      })
    }
  }

  generateAnimationCSS = memoize(
    (products, duration) => {
      if(!products || !duration) return ""
      let animationIncrement = 0.001
      let keys = products.map((group)=>{ return group.id })
      
      let animations = keys.map(key => {
        let frames = products.find((group)=>{ return group.id === key }).locs || []
        let cssFrames = frames.map(frameArr => {
            const frame = {ts: frameArr[0]/duration, t: frameArr[1], x: frameArr[2], y: frameArr[3]}
            let percent = frame.ts * 100
            switch(frame.t) {
              case 0:            
                return `${percent-animationIncrement}% { left: ${frame.x*100}%; top: ${(frame.y)*100}%; opacity: 0.0; transform: scale(0); } ${percent}% { left: ${frame.x*100}%; top: ${(frame.y)*100}%; opacity: 1.0; transform: scale(1); } `
                break;
              case 1:
                return `${percent}% { left: ${frame.x*100}%; top: ${(frame.y)*100}%; opacity: 1.0; transform: scale(1); }`
                break;
              case 2:
                return `${percent}% { left: ${frame.x*100}%; top: ${(frame.y)*100}%; opacity: 1.0; transform: scale(1); } ${percent+animationIncrement}% { left: ${frame.x*100}%; top: ${(frame.y)*100}%; opacity: 0; transform: scale(0.001); }`
                break;  
              default:
                break;
            }
        })
        let animationString = `@keyframes anim-${key}-freckle { 0% { opacity: 0.0; transform: scale(0); } ${cssFrames.join("\n")} 100% { opacity: 0.0; transform: scale(0); } }`
        return animationString
      })
      const full = animations.join("\n")
      return full
    }
  )

  selectedFreckle = (freckleId, freckleRef) => {
    this.videoPlayer.pause()
    document.dispatchEvent(new CustomEvent('freckleProductSelected', {bubbles: true, detail: {"productId": freckleId}}))
    this.setState({selectedFreckleId: freckleId, wasPlayingOnSelect: this.state.playing, selectedFreckleRef: freckleRef})
    Analytics.logEvent("hover", freckleId, this.state.videoId, this.videoPlayer.currentTime)
  }

  getFreckles = memoize((products, duration, selectedFreckle) => {
    var dur = 0
    if(!products || !duration) return <span/>
    if(this.videoPlayer != null) dur = this.videoPlayer.duration
    return products.map((product)=>{
      var freckleRef = this.freckleRefs[product.id]
      if(freckleRef == null) {
        freckleRef = createRef();
        this.freckleRefs[product.id] = freckleRef
      }

      const isSelected = product.id === this.state.selectedFreckleId
      const freckleName = "anim-" + product.id + "-freckle"

      return (
        <Freckle trackRef={freckleRef} freckleId={product.id} durationSeconds={duration} selected={this.selectedFreckle} isSelected={isSelected}/>
      )
    })
  })

  updateLocations = () => {
    const offset = -this.videoPlayer.currentTime 
    Object.values(this.freckleRefs).forEach((freckle)=>{
      freckle.current.style.animationDelay = offset + "s"
    })

    requestAnimationFrame(this.updateLocations.bind(this))
  }

  togglePlay = (e) => {
    console.log("toggling play: " + e.target)
    if(this.state.playing){
      this.videoPlayer.pause()
    }else{
      this.videoPlayer.play()
    }
  }

  configureEventListeners = () => {
    console.log("configure event listeners")
    const player = this.videoPlayer
    player.addEventListener("play", () => { 
      this.setState({playing: true, waitingToPlay: false, videoEnded: false})
      Analytics.logEvent("play video", "", this.state.videoId, this.videoPlayer.currentTime)
    }, true);

    player.addEventListener("ended", () => { 
      if(!eval(this.props.loop)) this.setState({playing: false, waitingToPlay: false, videoEnded: true})
        Analytics.logEvent("finished video", "", this.state.videoId, this.videoPlayer.currentTime)
    }, true);

    player.addEventListener("pause", () => { 
      this.setState({playing: false})
      Analytics.logEvent("pause video", "", this.state.videoId, this.videoPlayer.currentTime)
    }, true);

    player.addEventListener("canplay", (e) => { 
      const height = this.videoPlayer.videoHeight
      const width = this.videoPlayer.videoWidth
      const aspectRatio = height / width

      if(this.props.onCanplay) this.props.onCanplay({height: height, width: width})

      this.setState({canPlay: true, duration: player.duration, videoHeight: height, videoWidth: width, aspectRatio: aspectRatio}, ()=>{
        this.resizedVideo()
      })
    })
  }

  tappedBackground = (e) => {
    if(e.target.className === `tooltipBlur tooltipContainer` || e.target.className === `product-page-container` || e.target.className === "exit-button") {
      this.setState({selectedFreckleRef: null, selectedFreckleId: null})
      if(this.state.wasPlayingOnSelect) this.videoPlayer.play()
    }
  }

  closeProductView = (preventPlay=false) => {
    this.setState({selectedFreckleRef: null, selectedFreckleId: null})
    if(this.state.wasPlayingOnSelect && !preventPlay) this.videoPlayer.play()
  }

  scrub = (value) => {
    if(this.state.playing) this.videoPlayer.pause()
    this.videoPlayer.currentTime = this.videoPlayer.duration * value
  }

  productUrl = (handle) => {
    return `https://${this.state.shopBase}/products/${handle}`
  }

  getTooltip = memoize((freckleId) => {
    let currentTime = 0
    if(!!this.videoPlayer) currentTime = this.videoPlayer.currentTime
    let product = null
    if(freckleId) product = this.state.products.find((product)=> product.id === freckleId )
    return(
      <CSSTransition in={product !== null} timeout={250} classNames="add-video" unmountOnExit>
      <Product videoId={this.state.videoId} videoTime={currentTime} product={product} onClose={this.closeProductView} data={this.state.template}/>
      </CSSTransition>
    ) 
  })

  resizedVideo = () => {
    const player = this.videoPlayer
    var height = player.getBoundingClientRect().height
    var width = player.getBoundingClientRect().width

    const aspectRatio = this.state.aspectRatio
    const containerAspectRatio = height / width
    
    if(aspectRatio >= containerAspectRatio) {
      width = height / this.state.aspectRatio
    } else {
      height = this.state.aspectRatio * width
    }

    this.setState({freckleLayerHeight: height, freckleLayerWidth: width})
  }

  tappedVideo = (e) => {
    if(e.target.className !== 'freckleLayer') return
    if(mobile()){
      this.tapControls()
    } else {
      this.togglePlay(e)
    }
  }

  test = (e) => {
    if(!mobile()) this.tapControls()
  }

  tapControls = () => {
    this.setState({showsControls: true})
    if(!this.state.showsControls){
      this.setState({showsControls: true})
      if(this.controlsTimer) clearTimeout(this.controlsTimer)
      this.controlsTimer = setTimeout(()=>{
        this.setState({showsControls: false})
        this.controlsTime = null
      }, 3000)
    } else {
      if(mobile()){
        if(this.controlsTimer) clearTimeout(this.controlsTimer)
        this.setState({showsControls: false})
      } 
    }
  }

  getFreckleLayer = () => {
    if(this.state.loading === LOADING) return <span/>
    return(
      <div class="freckleLayer" style={{
        height: this.state.freckleLayerHeight+"px", 
        marginTop:-(this.state.freckleLayerHeight/2)+"px",
        width: this.state.freckleLayerWidth+"px",
        marginLeft:-(this.state.freckleLayerWidth/2)+"px",
        display: this.state.canPlay ? "block" : "none"
      }}>
        { 
          this.getFreckles(this.state.products, this.state.duration, this.state.selectedFreckleId)
        }
      </div>
    )
  }

  getControls = () => {
    if(!!!this.videoPlayer) return <span/>
    if(this.state.loading === LOADING) return <span/>
    const controlImage = this.state.playing ? pause : play
    const controlShift = this.state.playing ? "-2px": "2px"
    const volumeImage = this.videoPlayer.muted ? vol : volMuted
    const volumeShift = this.videoPlayer.muted ? "0":"-1px"
    const controlsClass = (this.state.waitingToPlay || this.state.videoEnded) ? 'controlsContainer hide' : `controlsContainer ${this.state.showsControls ? "show":"hide"}`
    return(
      <div class={controlsClass}>
        <button onclick={this.togglePlay} class="controlButton">
          <img style={{"marginLeft":controlShift}} src={`data:image/svg+xml;base64,${btoa(controlImage)}`}/>
        </button>
        <ScrubBar player={this.videoPlayer} valueChanged={this.scrub}/>
        <div class="volume-container">
          <button style={{"marginLeft":volumeShift}} class="volume-button" onClick={()=>{
            this.videoPlayer.muted = !this.videoPlayer.muted
            this.setState({loading: this.state.loading})
          }}>
            <img src={`data:image/svg+xml;base64,${btoa(volumeImage)}`}/>
          </button>
        </div>
      </div>
    )
  }

  getLoadingIndicator = () => {
    if(this.state.canPlay) return <span/>
    return(
      <div class="loadingContainer">
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
      </div>
    )
  }

  getPlayOverlay = () => {
    const icon = this.state.waitingToPlay ? playBlack : replayBlack
    const shift = this.state.waitingToPlay ? "5px" : "0"
    const overlayClass = (this.state.waitingToPlay || this.state.videoEnded) ? "playOverlay" : "playOverlay hidden"
    return(
      <div class={overlayClass}>
        <button class="overlayPlayButton" onClick={this.togglePlay}>
          <img style={{height: "30px", width: "30px", marginLeft: shift}} src={`data:image/svg+xml;base64,${btoa(icon)}`}/>
        </button>
      </div>
    )
  }

  generateTemplateCSS = memoize((data) => {
    if(!data) return ""
    return `

    .checkout-button {
      font-family: ${data.cart.checkoutButton.fontOptions.family || data.fontOptions.family} !important; 
      font-size: ${data.cart.checkoutButton.fontOptions.size}px !important;
      background-color: ${data.cart.checkoutButton.backgroundColor} !important;
      border: ${data.cart.checkoutButton.borderWidth}px solid ${data.cart.checkoutButton.borderColor} !important;
      color: ${data.cart.checkoutButton.textColor} !important;
      border-radius: ${data.cart.checkoutButton.borderRadius}px !important;
    }
    .checkout-button:hover {
      background-color: ${data.cart.checkoutButtonHover.backgroundColor} !important;
      border: ${data.cart.checkoutButtonHover.borderWidth}px solid ${data.cart.checkoutButtonHover.borderColor} !important;
      color: ${data.cart.checkoutButtonHover.textColor} !important;
    }
    .cart-item-variant {
      color: ${data.cart.item.variantName.textColor} !important;
      font-family: ${data.cart.item.variantName.fontOptions.family || data.fontOptions.family} !important; 
      font-size: ${data.cart.item.variantName.fontOptions.size}px !important;
      ${!!data.cart.item.variantName.fontOptions.lineHeight ? `line-height: ${data.cart.item.variantName.fontOptions.lineHeight}px;`:''}
      ${!!data.cart.item.variantName.fontOptions.spacing ? `letter-spacing: ${data.cart.variantName.fontOptions.spacing}px;`:''}
    }
    .cart-item-title {
      color: ${data.cart.item.title.textColor} !important;
      font-family: ${data.cart.item.title.fontOptions.family || data.fontOptions.family} !important; 
      font-size: ${data.cart.item.title.fontOptions.size}px !important;
      ${!!data.cart.item.title.fontOptions.lineHeight ? `line-height: ${data.cart.item.title.fontOptions.lineHeight}px;`:''}
      ${!!data.cart.item.title.fontOptions.spacing ? `letter-spacing: ${data.cart.title.fontOptions.spacing}px;`:''}
    }
    .cart-item-price {
      color: ${data.cart.item.price.textColor} !important;
      font-family: ${data.cart.item.price.fontOptions.family || data.fontOptions.family} !important; 
      font-size: ${data.cart.item.price.fontOptions.size}px !important;
      ${!!data.cart.item.price.fontOptions.lineHeight ? `line-height: ${data.cart.item.price.fontOptions.lineHeight}px;`:''}
      ${!!data.cart.item.price.fontOptions.spacing ? `letter-spacing: ${data.cart.price.fontOptions.spacing}px;`:''}
      }
      .quantity-container {
          font-size: ${data.cart.item.quantity.fontOptions.size}px !important;
          font-family: ${data.cart.item.quantity.fontOptions.family || data.fontOptions.family} !important; 
          color: ${data.cart.item.quantity.textColor} !important;
          ${!!data.cart.item.quantity.fontOptions.lineHeight ? `line-height: ${data.cart.item.quantity.fontOptions.lineHeight}px;`:''}
          ${!!data.cart.item.quantity.fontOptions.spacing ? `letter-spacing: ${data.cart.item.quantity.fontOptions.spacing}px;`:''}
        }
    .cart-footer {
      color: ${data.cart.subtotal.textColor} !important;
      font-family: ${data.cart.subtotal.fontOptions.family || data.fontOptions.family} !important; 
      font-size: ${data.cart.subtotal.fontOptions.size}px !important; 
      ${!!data.cart.subtotal.fontOptions.lineHeight ? `line-height: ${data.cart.subtotal.fontOptions.lineHeight}px;`:''}
      ${!!data.cart.subtotal.fontOptions.spacing ? `letter-spacing: ${data.cart.subtotal.fontOptions.spacing}px;`:''}
    }
    .cart-header {
      font-family: ${data.cart.header.fontOptions.family || data.fontOptions.family} !important; 
      font-size: ${data.cart.header.fontOptions.size}px !important; 
      ${!!data.cart.header.fontOptions.lineHeight ? `line-height: ${data.cart.header.fontOptions.lineHeight}px;`:''}
      ${!!data.cart.header.fontOptions.spacing ? `letter-spacing: ${data.cart.header.fontOptions.spacing}px;`:''} 
    }

    .pcontainer {
      font-family: ${data.font} !important;
    }
  
    .tooltip {
      border-radius: ${data.tooltip.borderRadius}px !important;
      font-family: ${data.tooltip.fontOptions.family || data.fontOptions.family} !important; 
    }
    .buy-now-button {
      font-family: ${data.tooltip.button.fontOptions.family || data.fontOptions.family} !important; 
      background-color: ${data.tooltip.button.backgroundColor} !important;
      border: ${data.tooltip.button.borderWidth}px solid ${data.footer.button.borderColor} !important;
      color: ${data.tooltip.button.textColor} !important;
      border-radius: ${data.tooltip.button.borderRadius}px !important;
      font-size: ${data.tooltip.button.fontOptions.size}px !important;
    }
    .buy-now-button:hover {
      background-color: ${data.tooltip.buttonHover.backgroundColor} !important;
      border: ${data.tooltip.button.borderWidth}px solid ${data.tooltip.buttonHover.borderColor} !important;
      color: ${data.tooltip.buttonHover.textColor} !important;
    }

    h3.product-title {
      font-size: ${data.tooltip.name.fontOptions.size}px !important;
      color: ${data.tooltip.name.textColor} !important;
      font-family: ${data.tooltip.name.fontOptions.family || data.fontOptions.family} !important; 
      ${!!data.tooltip.name.fontOptions.lineHeight ? `line-height: ${data.tooltip.name.fontOptions.lineHeight}px;`:''}
      ${!!data.tooltip.name.fontOptions.spacing ? `letter-spacing: ${data.tooltip.name.fontOptions.spacing}px;`:''}
    }

    h4.product-price {
      font-size: ${data.tooltip.price.fontOptions.size}px !important;
      font-family: ${data.tooltip.price.fontOptions.family || data.fontOptions.family} !important; 
      color: ${data.tooltip.price.textColor} !important;
      ${!!data.tooltip.price.fontOptions.lineHeight ? `line-height: ${data.tooltip.price.fontOptions.lineHeight}px;`:''}
      ${!!data.tooltip.price.fontOptions.spacing ? `letter-spacing: ${data.tooltip.price.fontOptions.spacing}px;`:''}
    }

    .description {
      font-size: ${data.tooltip.description.fontOptions.size}px !important;
      font-family: ${data.tooltip.description.fontOptions.family || data.fontOptions.family} !important; 
      color: ${data.tooltip.description.textColor} !important;
      ${!!data.tooltip.description.fontOptions.lineHeight ? `line-height: ${data.tooltip.description.fontOptions.lineHeight}px;`:''}
      ${!!data.tooltip.description.fontOptions.spacing ? `letter-spacing: ${data.tooltip.description.fontOptions.spacing}px;`:''}
    } 

    .footer-button.custom {
        display: ${data.footer.button.show?'flex':'none'} !important;
        background-color: ${data.footer.button.backgroundColor} !important;
        border: ${data.footer.button.borderWidth}px solid ${data.footer.button.borderColor} !important;
        color: ${data.footer.button.textColor} !important;
        border-radius: ${data.footer.button.borderRadius}px !important;
        font-family: ${data.fontOptions.family} !important;
        font-size: ${data.fontOptions.size}px !important;
        ${!!data.fontOptions.lineHeight ? `line-height: ${data.fontOptions.lineHeight}px;`:''}
        ${!!data.fontOptions.spacing ? `letter-spacing: ${data.fontOptions.spacing}px;`:''}
      }
      .footer-button.custom:hover {
        background-color: ${data.footer.buttonHover.backgroundColor} !important;
        border: ${data.footer.button.borderWidth}px solid ${data.footer.buttonHover.borderColor} !important;
        color: ${data.footer.buttonHover.textColor} !important;
      }
    `
  })

  fontLinks = memoize((data) => {
    const fonts = [...new Set([
      data.fontOptions.family,
      data.tooltip.name.fontOptions.family,
      data.tooltip.price.fontOptions.family,
      data.tooltip.description.fontOptions.family,
      data.tooltip.button.fontOptions.family,
      data.tooltip.variant.fontOptions.family,
    ])].filter(item => item !== null)
    return fonts.map(font => <link rel="stylesheet" href={`https://fonts.googleapis.com/css2?family=${encodeURI(font)}:wght@200;300;400;600&display=swap`}/>)
  })

  render() {
    if(this.state.loading === LOADING) {
      return (
        <div class="container" style={this.props.style}>
          <div class="loadingContainer">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
          </div>
        </div>
      )
    } 
    else if(this.state.loading === LOAD_FAIL) {
      return <div class="loadContainer"><h1>Not Found</h1>Uh oh!  We weren't able to find your video...</div>
    }

    const freckleCss = this.generateAnimationCSS(this.state.products, this.state.duration)

    const containerStyle = {...this.props.style}
    if(eval(this.props.autoHeight)){
      try {
        containerStyle.height = (this.state.aspectRatio * this.videoPlayer.getBoundingClientRect().width) + "px"
      } catch(e) {}
    } else if(eval(this.props.autoWidth)) {
      try {
        containerStyle.width = (this.videoPlayer.getBoundingClientRect().height / this.state.aspectRatio) + "px"
      } catch(e) {}
    }

    // console.log("style: " + JSON.stringify(containerStyle) + ", props: " + JSON.stringify(this.props))
   	return(
        <div class="container" onMouseMove={this.test} key={this.state.loading} style={containerStyle}>
          { this.fontLinks(this.state.template) }
          <style>
          { this.generateTemplateCSS(this.state.template) }
          </style>
          { this.getPlayOverlay() }
          <ReactResizeDetector handleWidth handleHeight onResize={this.resizedVideo}>
         		<div class="videoContainer" onClick={this.tappedVideo}>
              <style>{
                freckleCss
              }</style>
        			<video key={this.props.videoid} ref={ node =>{
                this.videoPlayer = node
              }}
            controls={false} 
            autoPlay={eval(this.props.autoplay)} 
            playsInline 
            loop={eval(this.props.loop)}
            muted={eval(this.props.muted)}>
        			</video>

              {
                this.getLoadingIndicator()
              }
              
              { 
                this.getFreckleLayer() 
              }
              <div class="controlsSuperContainer">
          			{ this.getControls() }
              </div>

        		</div>
          </ReactResizeDetector>


          <div onClick={this.tappedBackground} class={this.state.selectedFreckleRef ? `tooltipBlur tooltipContainer` : 'tooltipContainer'}>
            { this.getTooltip(this.state.selectedFreckleId) }
          </div>
        </div>
   	)
  }
}
register(Player, 'freckle-video', [], { shadow: true })

