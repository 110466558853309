import { h, Component } from 'preact';
import * as React from 'preact';
import * as ReactDOM from 'preact';
import CartManager from '../../helpers/cart-manager.js'
import style from './style.scss';

export default class Cart extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
		};
	}

	componentDidMount() {
		document.addEventListener('freckleCartUpdated', this.reloadCart.bind(this));
		document.addEventListener('freckleCartLoaded', this.reloadCart.bind(this));
	}

	reloadCart = () => {
		this.forceUpdate()
	}

	incrementQuantity = (item) => {
		CartManager.add({id: item.id}, item.variant)
	}

	decrementQuantity = (item) => {
		CartManager.remove({id: item.id}, item.variant)
	}

	checkout = () => {
		const products = CartManager.allItems()
		const cartString =  Object.keys(products).map(key => `${products[key].variant.id}:${products[key].quantity}`).join(",")
		const url = `https://${this.props.data.shopifyStore}/cart/${cartString}`
		window.open(url)
	}

	render(){
		let products = CartManager.allItems()
		let cartSubtotal = 0
		if(Object.keys(products).length > 0) cartSubtotal = Object.keys(products).map(key => products[key].quantity * products[key].price).reduce((a,b)=> parseInt(a)+parseInt(b)).toFixed(2) / 100

		return(
		<div class="cart-super-container">
			<div class="cart-background" onClick={this.props.onClose}/>
			<div class="cart-container">
				<div className="cart-header">
					<span>CART</span>
					<img  onClick={this.props.onClose} src={`data:image/svg+xml;base64,Cjxzdmcgd2lkdGg9IjY0cHgiIGhlaWdodD0iNjRweCIgdmlld0JveD0iMCAwIDY0IDY0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkFydGJvYXJkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjI0LjAwMDAwMCwgLTE5MS4wMDAwMDApIj4KICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIyNC4wMDAwMDAsIDE5MS4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxjaXJjbGUgaWQ9Ik92YWwiIGZpbGw9IiNGRkZGRkYiIGN4PSIzMiIgY3k9IjMyIiByPSIzMiI+PC9jaXJjbGU+CiAgICAgICAgICAgICAgICA8ZyBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMxLjUwMDAwMCwgMzIuNTAwMDAwKSByb3RhdGUoNDUuMDAwMDAwKSB0cmFuc2xhdGUoLTMxLjUwMDAwMCwgLTMyLjUwMDAwMCkgdHJhbnNsYXRlKDE4LjAwMDAwMCwgMTkuMDAwMDAwKSIgZmlsbD0iIzAwMDAwMCI+CiAgICAgICAgICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTMuNTAwMDAwLCAxMy41MDAwMDApIHJvdGF0ZSg5MC4wMDAwMDApIHRyYW5zbGF0ZSgtMTMuNTAwMDAwLCAtMTMuNTAwMDAwKSAiIHg9IjExIiB5PSIwIiB3aWR0aD0iNSIgaGVpZ2h0PSIyNyI+PC9yZWN0PgogICAgICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUtQ29weSIgeD0iMTEiIHk9IjAiIHdpZHRoPSI1IiBoZWlnaHQ9IjI3Ij48L3JlY3Q+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=`}/>
				</div>
				<div className="items">
				{ 
					Object.keys(products).map(productKey => {
						const item = products[productKey]
						return(
						<div className="cart-item">
							<img src={ item.images[0].replace(/\.jpg|\.png|\.gif|\.jpeg/g, (match) => '_compact'+match ) }/>
							<div className="item-right">
								<div className="item-text">
									<div className="cart-item-title">{ item.title }</div>
									<div className="cart-item-price">${ item.price / 100 }</div>
								</div>
								<div className="item-text">
									<div className="cart-item-variant">{ item.variant.title }</div>
								</div>
								<div className="quantity-container">
									<div className="control" onClick={()=>this.decrementQuantity(item)}>-</div><div className="quantity">{ item.quantity }</div><div className="control" onClick={()=>this.incrementQuantity(item)}>+</div>
								</div>
							</div>
						</div>
						)
					})
				}
				</div>
				<div class="cart-footer">
					<div class="subtotal-line">
						<div className="label">Subtotal</div>
						<div className="cart-price">${ cartSubtotal }</div>
					</div>
				<button className="checkout-button" onClick={this.checkout}>CHECKOUT</button>
				</div>
			</div>
		</div>
		)
	}
}

