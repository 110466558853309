export const exitProductView = `
<svg width="64px" height="64px" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Artboard" transform="translate(-224.000000, -191.000000)">
            <g id="Group-2" transform="translate(224.000000, 191.000000)">
                <circle id="Oval" fill="#FFFFFF" cx="32" cy="32" r="32"></circle>
                <g id="Group" transform="translate(31.500000, 32.500000) rotate(45.000000) translate(-31.500000, -32.500000) translate(18.000000, 19.000000)" fill="#000000">
                    <rect id="Rectangle" transform="translate(13.500000, 13.500000) rotate(90.000000) translate(-13.500000, -13.500000) " x="11" y="0" width="5" height="27"></rect>
                    <rect id="Rectangle-Copy" x="11" y="0" width="5" height="27"></rect>
                </g>
            </g>
        </g>
    </g>
</svg>
`