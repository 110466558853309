import * as React from 'preact';
import * as ReactDOM from 'preact';

import CartManager from '../../helpers/cart-manager.js'

import { CSSTransition } from 'react-transition-group'

import { defaultCart } from './button-vectors'

import Player from '../player'
import Cart from '../cart'
import style from './style.scss';

const axios = require('axios')

export default class Microsite extends React.Component {

  constructor(props, context) {
  	super(props, context);

  	this.state = {
      data: null,
      header: 'the thing',
      showsCart: false,
      videoDims: {
        height: 0,
        width: 0
      }
    }

  }

  fetchManifest = async () => {
    try {
      const resp = await axios.get(`https://stream.freckle.shop/${this.props.videoid}/manifests/${this.props.versionid}.json`)
      this.setState({data: resp.data})
    } catch (e) {
    }
  }

  listenForBootstrapPayload = () => {
    window.addEventListener("message", (event) => {
      const { data } = event
      if(!!data.shopifyStore){
        console.log("got bootstrapped data: " + JSON.stringify(data))
        this.setState({data: data})
      }
    }, false)
  }

  componentWillUnmount() {

  }

  componentDidMount() {
    if(!!this.props.bootstrap) {
      this.listenForBootstrapPayload()
    } else {
      this.fetchManifest() 
    }

    window.addEventListener('resize', ()=>{
      this.forceUpdate()
    })

    CartManager.setStoreId(this.props.videoid)
    document.addEventListener('freckleCartUpdated', ()=>{
      this.setState({showsCart: true})
    });
  }

  playerStyle = () => {
    const w = window.innerWidth
    const h = window.innerHeight

    if(h*0.75 > w && w < 1000) {
      // mobile
      return {
        width: '100%',
        marginBottom: '5vh'
      }
    } else {
      // desktop
      return {
        width: '80vw',
        maxWidth: '1000px',
        margin: '5vh',
        height: '70vh'
      }
    }
  }

  headerImage = () => {
    const { data } = this.state
    if(data) { 
      const logoData = data.template.header.logo.data
      if(logoData) return <img class="header-img" src={logoData}/>
      return <span/>
    }
  }

  cartImage = () => {
    const { data } = this.state
    const count = CartManager.count()
    if(data) { 
      let logoData = data.template.header.cartIcon.data
      if(!!!logoData) logoData = `data:image/svg+xml;base64,${btoa(defaultCart)}`
      if(logoData) return(
        <span>
        <img class="cart-img" style={{
          height: `calc(${data.template.header.cartIcon.size}% - 5px)`
        }} src={logoData}/>
        <div class="cart-count" style={{
          display: !!count ? 'flex' : 'none',
          backgroundColor: data.template.header.count.backgroundColor
        }}>
          { count }
        </div>
        </span>
      )
      return <span/>
    }
  }

  generateTemplateCSS = () => {
    if(!this.state.data) return ""
    const template = this.state.data.template
  
    return `
    .microsite {
      font-family: ${template.font} !important;
      background-color: ${template.color} !important;
    }
    .header {
      background-color: ${template.header.color} !important;
      border-bottom: ${template.header.borderWidth}px solid ${template.header.borderColor} !important;
    }
    .header-img {
      transform: scale(${template.header.logo.size/100}) !important;
    }
    .cart-img {
      height: calc(${template.header.cartIcon.size}% - 5px) !important;
    }
    .footer-button.custom {
      display: ${template.footer.button.show?'flex':'none'} !important;
      background-color: ${template.footer.button.backgroundColor} !important;
      border: ${template.footer.button.borderWidth}px solid ${template.footer.button.borderColor} !important;
      color: ${template.footer.button.textColor} !important;
      border-radius: ${template.footer.button.borderRadius}px !important;
    }
    .footer-button.custom:hover {
      background-color: ${template.footer.buttonHover.backgroundColor} !important;
      border: ${template.footer.button.borderWidth}px solid ${template.tooltip.buttonHover.borderColor} !important;
      color: ${template.footer.buttonHover.textColor} !important;
    }
    `
  }

  fontImport = () => {
    if(!this.state.data) return ""
    return <link rel="stylesheet" href={`https://fonts.googleapis.com/css2?family=${encodeURI(this.state.data.template.fontOptions.family)}:wght@200;300;400;600&display=swap`}/>
  }

  receiveDimensions = (dims) => this.setState({videoDims: dims})

  getCart = () => {
    return(
      <CSSTransition in={this.state.showsCart} timeout={250} classNames="show-cart" unmountOnExit>
      <Cart data={this.state.data} 
      onClose={()=>{
        this.setState({showsCart: false})
      }}/>
      </CSSTransition>
    )
  }

  render() {
    const isPhone = window.innerWidth < window.innerHeight*0.75
    const videoIsLandscape = this.state.videoDims.width > this.state.videoDims.height
    
    const autoHeight = (videoIsLandscape && !isPhone) || (isPhone)
    const autoWidth = !videoIsLandscape && !isPhone

    const { data } = this.state

    const dataString = data ? JSON.stringify(data) : null
    
    const mainHref = data ? data.template.footer.button.href : ""
    const contText = data ? data.template.footer.button.text : ""
    return (
      <div class="microsite">
        { this.fontImport() }
        <style>
        { this.generateTemplateCSS() }
        </style>
        { this.getCart() }
        <div class="header">
          <div class="shim"/>
          { this.headerImage() }
          <button class="cart-button" onClick={()=> this.setState({showsCart: true})}>
          { this.cartImage() }
          </button>
        </div>
        <div class="player-container">
          <Player
          videoid={this.props.videoid} local="true" data={dataString} autoplay="true" muted="true" loop="true" hidesfreckles="false" 
          autoHeight={autoHeight}
          autoWidth={autoWidth}
          style={this.playerStyle()}
          onCanplay={this.receiveDimensions}
          />
        </div>
        <div class="footer">
          <a class="footer-button custom" href={mainHref}>{contText}</a>
        </div>
      </div>
    )
  }

}
