import { h, Component } from 'preact';
import * as React from 'preact';
import * as ReactDOM from 'preact';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Thumbs } from 'swiper/core';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

import { currency_symbols } from '../../helpers/currency-symbols.js'
import axios from 'axios'

import { exitProductView } from './button-vectors'

import style from './style.scss';

import memoize from "memoize-one"
import Analytics from '../../helpers/analytics'
import PickerMenu from './picker-menu'
import CartManager from '../../helpers/cart-manager.js'

const TOOLTIP_WIDTH = 300
const TOOLTIP_HEIGHT = 100

export default class Product extends React.Component {
	constructor(props, context) {
		super(props, context);
		console.log("making product")

		SwiperCore.use([Thumbs])

		props.product && this.fetchProduct()

		this.state = {
			product: null,
			selectedVariant: null,
			thumbsSwiper: null
		};
	}

	fetchProduct = () => {
		axios.get(`${this.props.product.linkUrl}.js`).then(resp => {
			this.setState({product: resp.data, selectedVariant: resp.data.variants[0]})
		})
	}

	buyNow = () => {
		const product = this.state.product
		const selectedVariant = this.state.selectedVariant
		Analytics.logEvent("add to cart", product.id, this.props.videoId, this.props.videoTime)
		CartManager.add(product, selectedVariant)
		this.props.onClose(true)
	}

	variantsData = memoize((product) => {
		let option1 = [], 
			option2 = [], 
			option3 = []
		product.variants.forEach(variant => {
			if(!!variant.option1 && variant.option1 !== 'None') option1.push(variant.option1)
			if(!!variant.option2 && variant.option2 !== 'None') option2.push(variant.option2)
			if(!!variant.option3 && variant.option3 !== 'None') option3.push(variant.option3)
		})
		option1 = [...new Set(option1)]
		option2 = [...new Set(option2)]
		option3 = [...new Set(option3)]
		return {
			option1: option1,
			option2: option2,
			option3: option3
		}
	})

	findVariantWithOptions = (option1, option2, option3) => {
		return this.state.product.variants.filter(variant => variant.option1 === option1 && variant.option2 === option2 && variant.option3 === option3)[0]
	}

	variantsSection = (product) => {
		if(!product) return <span/>
		const variantData = this.variantsData(product)

		if(variantData.option1.length <= 1 && variantData.option2.length <= 1 && variantData.option3.length <= 1) return <span/>

		var { selectedVariant } = this.state
		const { data } = this.props
		return(
			<div className="variants-container">
			<style>
			{`
				.picker-menu {
					border-radius: ${data.tooltip.variant.borderRadius}px !important;
        			border: ${data.tooltip.variant.borderWidth}px solid ${data.tooltip.variant.borderColor} !important;
        			${!!data.tooltip.variant.fontOptions.lineHeight ? `line-height: ${data.tooltip.variant.fontOptions.lineHeight}px;`:''}
					${!!data.tooltip.variant.fontOptions.spacing ? `letter-spacing: ${data.tooltip.variant.fontOptions.spacing}px;`:''}
				}
				.picker-select-box {
					background-color: ${data.tooltip.variant.backgroundColor} !important;
					font-family: ${data.tooltip.variant.fontOptions.family || data.fontOptions.family} !important; 
        			color: ${data.tooltip.variant.textColor} !important;
        			font-size: ${data.tooltip.variant.fontOptions.size}px !important;
        			font-weight: ${data.tooltip.variant.fontOptions.weight} !important;
				}
				.picker-options-container {
					background-color: ${data.tooltip.variant.backgroundColor} !important;
					font-family: ${data.tooltip.variant.fontOptions.family || data.fontOptions.family} !important; 
        			color: ${data.tooltip.variant.textColor} !important;
        			font-size: ${data.tooltip.variant.fontOptions.size}px !important;	
        			font-weight: ${data.tooltip.variant.fontOptions.weight} !important;
				}
			`}
			</style>
			{ variantData.option1.length > 1 && <PickerMenu key='dropdown' options={variantData.option1} value={this.state.selectedVariant.option1 || variantData.option1[0]} onChange={(option)=>{
				const newSelectedVariant = this.findVariantWithOptions(option, selectedVariant.option2, selectedVariant.option3)
				this.setState({selectedVariant: newSelectedVariant})
			}}/> }
			{ variantData.option2.length > 1 && <PickerMenu key='dropdown' options={variantData.option2} value={this.state.selectedVariant.option2 || variantData.option2[0]} onChange={(option)=>{
				const newSelectedVariant = this.findVariantWithOptions(selectedVariant.option1, option, selectedVariant.option3)
				this.setState({selectedVariant: newSelectedVariant})
			}}/> }
			{ variantData.option3.length > 1 && <PickerMenu key='dropdown' options={variantData.option3} value={this.state.selectedVariant.option3 || variantData.option3[0]} onChange={(option)=>{
				const newSelectedVariant = this.findVariantWithOptions(selectedVariant.option1, selectedVariant.option2, option)
				this.setState({selectedVariant: newSelectedVariant})
			}}/> }
			</div>
		)
	}

	tooltipContents = () => {
		const product = this.state.product
		if(!product) return <div class="tooltip in"><div class="exit-button"><img src={`data:image/svg+xml;base64,${btoa(exitProductView)}`}/></div></div>

			const price = `$${product.price / 100}`

		return(
			<div class="tooltip in">
			<div class="exit-button" onClick={this.props.onClose}>
			<img src={`data:image/svg+xml;base64,${btoa(exitProductView)}`}/>
			</div>
			<div class="scroll">
			<Swiper 
			thumbs={{ swiper: this.state.thumbsSwiper }} 
			loop={true}
			spaceBetween={10}
			slidesPerView={1}>
			{
				product.images.map(image => <SwiperSlide><img src={image.replace(/\.jpg|\.png|\.gif|\.jpeg/g, (match) => '_grande'+match )} class="product-image"/></SwiperSlide>)
			}
			</Swiper>
			<Swiper
			class="thumb-swiper"
			watchSlidesVisibility
			watchSlidesProgress
			spaceBetween={0}
			slidesPerView={5}
			onSwiper={(swiper)=>{ this.setState({thumbsSwiper: swiper}) }}
			>
			{
				product.images.map(image => <SwiperSlide><img src={image.replace(/\.jpg|\.png|\.gif|\.jpeg/g, (match) => '_compact'+match )} class="product-thumb"/></SwiperSlide>)
			}
			</Swiper>
			<div class="details">
			<h3 class="product-title">{product.title}</h3>
			<h4 class="product-price">{price}</h4>
			{ this.variantsSection(this.state.product) }
			<div class="description" dangerouslySetInnerHTML={{__html: product.description}}>
			</div>
			</div>
			</div>
			<button onClick={()=>{this.buyNow()}} class="buy-now-button">ADD TO CART</button>
			</div>
			)

	}

	render(){
		return(
			<div class="product-page-container">
			{ this.tooltipContents() }
			</div>
			)
	}
}